import React from "react";
import PropTypes from "prop-types";
import { Link } from "gatsby";
import cx from "classnames";

import SmallArrow from "../../images/svg/arrows/arrow_top-right_small.svg";

function ArrowLink({
  cta = "Find out more",
  to = "/",
  inline = false,
  className,
}) {
  return (
    <Link
      to={to}
      className={cx(
        "cursor-pointer group/cta",
        inline ? "inline" : "block h-full",
      )}
    >
      <div className={cx("flex", className)}>
        <span className="font-dm-sans font-bold antialiased text-black text-xl lg:text-2xl uppercase underline leading-none">
          {cta}
        </span>
        <SmallArrow className="w-[19px] h-[19px] ml-2 group-hover/cta:text-apricot" />
      </div>
    </Link>
  );
}

export default ArrowLink;

ArrowLink.propTypes = {
  cta: PropTypes.string,
  to: PropTypes.string,
  inline: PropTypes.bool,
  className: PropTypes.string,
};
