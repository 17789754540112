import * as React from "react";
import PropTypes from "prop-types";
import { Link } from "gatsby";

import Heading from "../../common/Heading";
import Paragraph from "../../common/Paragraph";
import Button from "../../common/Button";
import Image from "../../common/Image";
import Arrow from "../../common/Arrow";

const Features = ({ slice }) => {
  const heading = slice.primary.heading;
  const features = slice.items;

  return (
    <section className="bg-limestone pt-14 pb-8 md:pt-24 md:pb-16">
      <div className="container md:flex justify-between">
        <div className="flex flex-row md:flex-col justify-between w-full md:w-5/12 xl:w-4/12">
          <Heading
            level="h4"
            className="text-center md:text-right md:border-r pb-14 md:pb-0 md:pr-10"
          >
            {heading.text}
          </Heading>
          <Arrow className="hidden md:block -rotate-90" />
        </div>
        <div className="lg:w-1/2">
          {features.map((feature, i) => {
            return (
              <div
                key={`feature-${i}`}
                className="flex md:flex-col lg:flex-row items-center gap-x-8 md:min-h-[150px] border-b pb-10 mb-10 md:pb-14 md:mb-14 lg:pb-20 lg:mb-20 last:pb-0 last:border-0 last:mb-0"
              >
                <Image
                  image={feature.feature_icon}
                  alt={feature.feature_icon.alt}
                  className="min-w-[8rem]"
                />
                <div className="flex flex-col justify-center md:w-3/5">
                  <Heading level="h6" className="pb-1">
                    {feature.feature_heading.text}
                  </Heading>
                  <Paragraph>{feature.feature_description.text}</Paragraph>
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <div className="container flex justify-center py-10 md:pt-24 md:pb-20">
        {/* <Link to="/how-it-works">
          <Button>Find out how it works</Button>
        </Link> */}
      </div>
    </section>
  );
};

Features.propTypes = {
  slice: PropTypes.shape({
    primary: PropTypes.shape({
      heading: PropTypes.shape({ text: PropTypes.string }),
    }),
    items: PropTypes.arrayOf(
      PropTypes.shape({
        feature_heading: PropTypes.shape({ text: PropTypes.string }),
        feature_description: PropTypes.shape({ text: PropTypes.string }),
        feature_icon: PropTypes.shape({
          alt: PropTypes.string,
        }),
      }),
    ),
  }),
};

export default Features;
