import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";

function Flag({
  children,
  variant = "primary",
  size = "base",
  pin = null,
  className = null,
}) {
  return (
    <span
      className={cx(
        "py-1 px-4 uppercase font-dm-sans font-medium italic",
        variant === "primary" && "bg-black text-lime",
        variant === "secondary" && "bg-lime text-black",
        size === "sm" && "text-xs md:text-sm",
        size === "base" && "text-sm md:text-base",
        size === "lg" && "text-base md:text-xl",
        pin === "right" && "absolute right-0 pr-4 md:pr-8",
        pin === "left" && "absolute left-0 pl-4 md:pl-8",
        className && className,
      )}
    >
      {children}
    </span>
  );
}

export default Flag;

Flag.propTypes = {
  children: PropTypes.string,
  size: PropTypes.oneOf(["sm", "base", "lg"]),
  variant: PropTypes.oneOf(["primary", "secondary"]),
  pin: PropTypes.oneOf(["left", "right"]),
  className: PropTypes.string,
};
