import * as React from "react";
import PropTypes from "prop-types";
import { SliceZone } from "@prismicio/react";

import { components } from "../components/home/slices";
import Layout from "../components/common/Layout";

const Homepage = ({ data, location }) => {
  const searchParam = location.search;
  const homepageSlices = data?.data.body || null;

  return (
    <Layout>
      {homepageSlices && (
        <SliceZone
          slices={homepageSlices}
          components={components}
          context={searchParam}
        />
      )}
    </Layout>
  );
};

Homepage.propTypes = {
  data: PropTypes.shape({
    prismicHomepage: PropTypes.shape({
      data: PropTypes.shape({
        body: PropTypes.arrayOf(
          PropTypes.shape({
            id: PropTypes.string,
            primary: PropTypes.object,
            slice_type: PropTypes.string,
          }),
        ),
      }),
    }),
  }),
};

export default Homepage;
