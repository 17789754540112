export const MC_AUDIENCE_ALL_CUSTOMERS = "c72b3764af";

export const MC_TAG_NEWSLETTER_SIGNUP = "2904218"; // newsletter-signup

export const MC_TAG_WAITLIST_TINYLEAF = "2903623"; //waitlist_tinyleaf
export const MC_TAG_WAITLIST_MIMO = "2903624"; //waitlist_mimo
export const MC_TAG_WAITLIST_GEO = "2903625"; //waitlist_geo
export const MC_TAG_WAITLIST_UNSPECIFIED = "2903626"; //unspecified_project_waitlist

export const PROJ_ID_TINYLEAF = "tinyleaf-9hau5mju79qjqewn9v2v";
export const PROJ_ID_MIMO = "mimo-x6kvrcvzd6ysiizogrqu";
export const PROJ_ID_GEO = "geo-lewrnp0xdo6mo8jvh3lm";
export const PROJ_ID_UNSPECIFIED = undefined;

export const MC_WAITLIST_TAGS = {
  "tinyleaf-9hau5mju79qjqewn9v2v": MC_TAG_WAITLIST_TINYLEAF,
  "mimo-x6kvrcvzd6ysiizogrqu": MC_TAG_WAITLIST_MIMO,
  "geo-lewrnp0xdo6mo8jvh3lm": MC_TAG_WAITLIST_GEO,
  undefined: MC_TAG_WAITLIST_UNSPECIFIED,
};
