import React, { useContext } from "react";
import { createPortal } from "react-dom";

import { ModalContext } from "../../context/modalContext";

import Close from "../../images/svg/close.svg";

function Modal({ children }) {
  const { closeModal } = useContext(ModalContext);

  return (
    <>
      {createPortal(
        <div className="modal z-20 h-screen sticky top-0 right-0 bottom-0 left-0 bg-alpha-black-70 pt-10 md:pt-20 overflow-auto">
          <div className="container bg-white p-10 w-full lg:max-w-3xl xl:max-w-5xl mb-10">
            <div className="flex justify-end">
              <Close
                className="mb-6 text-black cursor-pointer"
                onClick={() => closeModal()}
              />
            </div>
            {children}
          </div>
        </div>,
        document.body,
      )}
    </>
  );
}

export default Modal;
