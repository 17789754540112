import Hero from "./Hero";
import Statement from "./Statement";
import Features from "./Features";
import FeaturedProjects from "./FeaturedProjects";
import JoinUs from "../../slices/JoinUs";

export const components = {
  hero: Hero,
  statement: Statement,
  features: Features,
  featured_challenges: FeaturedProjects,
  join_us: JoinUs,
};
