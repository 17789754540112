import React from "react";
import PropTypes from "prop-types";

import cx from "classnames";

function Tag({ name, isRequired }) {
  return (
    <span
      className={cx(
        "h-6 pr-2 pl-3 py-[3px] md:h-8 md:pr-4 md:pl-5 md:py-1 font-dm-sans text-xs md:text-sm leading-snug md:leading-normal border-1 border-black rounded-t-full rounded-br-full whitespace-nowrap",
        isRequired ? " text-white bg-black" : "bg-white text-black",
      )}
    >
      {name}
    </span>
  );
}

export default Tag;

Tag.propTypes = {
  name: PropTypes.string,
  isRequired: PropTypes.bool,
};
