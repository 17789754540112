import * as React from "react";
import { Link } from "gatsby";

import Button from "../../common/Button";
import Heading from "../../common/Heading";
import Paragraph from "../../common/Paragraph";
import Shape from "../../common/Shape";

const Hero = ({ slice }) => {
  const content = slice.primary;

  return (
    <section className="relative pt-14 pb-20 lg:pb-40 xl:pb-64 md:mx-12 z-0">
      <Shape
        type="lines"
        style="fill"
        className="absolute top-10 xl:top-40 -left-20 lg:left-12 w-40 lg:w-60 xl:w-80 text-limestone -z-10 md:animate-float-slow"
      />
      <div className="absolute bottom-0 md:bottom-20 xl:bottom-10 -left-10 md:left-2 lg:left-40 xl:left-80 4xl:left-96 w-40 lg:w-60 xl:w-80 -z-10 rotate-180">
        <Shape
          type="circle"
          style="fill"
          className="text-apricot md:animate-wiggle"
        />
      </div>
      <Shape
        type="quarter"
        style="outline"
        className="absolute bottom-20 lg:bottom-36 xl:bottom-28 right-0 lg:right-20 3xl:right-40 w-40 lg:w-60 xl:w-80 text-eucalyptus -z-10 md:animate-spin-slow"
      />
      <div className="container text-center flex-col items-center mb-10">
        <Heading level="super" className="py-10 md:py-20 lg:px-20 xl:px-40">
          {content.heading.text}
          <br />
          {content.heading_line_2.text}
        </Heading>
        <Paragraph size="xl" className="pb-8">
          {content.subheading.text}
          <br />
          {content.subheading_line_2.text}
        </Paragraph>
        <Button size="lg">
          <Link to="/projects">EXPLORE PROJECTS</Link>
        </Button>
      </div>
    </section>
  );
};

export default Hero;
