import * as React from "react";
import PropTypes from "prop-types";

import Heading from "../../common/Heading";
import Arrow from "../../common/Arrow";

const Statement = ({ slice }) => {
  const content = slice.primary;

  return (
    <section className="pt-10 pb-28 lg:pt-28 lg:pb-48">
      <div className="container md:flex justify-center gap-20 xl:gap-40">
        <Arrow className="hidden md:block -rotate-90 w-24 lg:w-36" />
        <Heading level="h5" className="md:w-8/12 xl:w-7/12 flex items-center">
          {content.statement.text}
        </Heading>
      </div>
    </section>
  );
};

Statement.propTypes = {
  slice: PropTypes.shape({
    primary: PropTypes.shape({
      statement: PropTypes.shape({ text: PropTypes.string }),
    }),
  }),
};

export default Statement;
