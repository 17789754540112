import React from "react";
import PropTypes from "prop-types";

import Heading from "../common/Heading";
import Shape from "../common/Shape";
import Arrow from "../common/Arrow";
import Paragraph from "../common/Paragraph";
import SubscribeForm from "../common/SubscribeForm";

function JoinUs({ slice, context }) {
  const inspiration = slice.items;
  const content = slice.primary;

  const queryParams = context;
  const hasSubscribed = queryParams.includes("subscribed");

  return (
    <section className="bg-limestone py-16 md:py-24 lg:py-32 relative z-0">
      <div className="container flex justify-between items-center pb-16 md:pb-32">
        <div className="w-full md:w-9/12 lg:w-8/12">
          <Heading level="h5" className="">
            {inspiration.map((phrase, i) => {
              return (
                <span key={i} className="block">
                  {phrase.large_cta_part}
                </span>
              );
            })}
          </Heading>
          <Arrow className="w-24 mt-10" type="down-right" />
        </div>
        <Shape
          type="circle"
          className="w-5/12 md:w-3/12 text-eucalyptus md:animate-float-slow"
        />
      </div>
      <section
        id="signup"
        className="grid grid-cols-1 md:grid-cols-[1fr_4fr] lg:grid-cols-[3fr_2fr] w-full lg:w-9/12 z-10"
      >
        <hr className="hidden md:block col-span-1 md:col-start-1 self-end w-full mb-3" />
        <Heading level="h3" className="md:col-start-2 mx-6 md:ml-6">
          {content.main_join_us_cta}
        </Heading>

        <Paragraph className="md:col-start-2 row-start-2 pt-6 mx-6 md:ml-6">
          {content.main_cta_detail}
        </Paragraph>
        <Paragraph className="md:col-start-2 row-start-3 pt-2 mx-6 md:ml-6">
          It&apos;s free to join!
        </Paragraph>
        <div className="md:col-start-2 row-start-4 pt-6 mx-6 md:ml-6">
          {hasSubscribed ? (
            <Paragraph colour="eucalyptus" size="sm">
              Thank you for signing up! Check your inbox.
            </Paragraph>
          ) : (
            <SubscribeForm />
          )}
        </div>
      </section>
      <Shape
        type="quarter"
        className="w-1/6 text-lime absolute bottom-0 left-0 rotate-90 -z-10"
      />
    </section>
  );
}

JoinUs.propTypes = {
  slice: PropTypes.shape({
    primary: PropTypes.shape({
      main_join_us_cta: PropTypes.string,
      main_cta_detail: PropTypes.string,
      sign_up_button_label: PropTypes.string,
    }),
    items: PropTypes.arrayOf(
      PropTypes.shape({
        large_cta_part: PropTypes.string,
      }),
    ),
  }),
};

export default JoinUs;
