import React from "react";
import PropTypes from "prop-types";
import { Link } from "gatsby";

import Section from "../../common/Section";
import ArrowLink from "../../common/ArrowLink";

import ProjectSpotlight from "../../project/ProjectSpotlight";

const FeaturedProjects = ({ slice }) => {
  const content = slice.primary;
  const projects = slice.items;

  return (
    <Section
      title={content.heading.text}
      intro={content.intro}
      fullWidth
      noPadding
      className="pt-12 md:pt-20"
    >
      {projects.map((project, i) => {
        const proj = project.featured_challenge.document.data;
        return (
          <div
            className="block border-black border-t last:border-b"
            key={`proj-${i}`}
          >
            <ProjectSpotlight
              project={proj}
              slug={project.featured_challenge.slug}
            />
          </div>
        );
      })}
      <ArrowLink
        cta="See all projects"
        to="projects"
        className="justify-center items-center py-16 px-8 w-full border-black border-t"
      />
    </Section>
  );
};

FeaturedProjects.propTypes = {
  slice: PropTypes.shape({
    primary: PropTypes.shape({
      intro: PropTypes.string,
    }),
    items: PropTypes.array,
  }),
};

export default FeaturedProjects;
