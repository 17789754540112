import React, { useRef } from "react";
import PropTypes from "prop-types";

import Image from "../common/Image";
import Paragraph from "../common/Paragraph";
import Heading from "../common/Heading";
import List from "../common/List";
import Tag from "../common/Tag";
import Level from "../common/Level";
import ArrowLink from "../common/ArrowLink";
import Flag from "../common/Flag";

function ProjectSpotlight({ project, slug }) {
  const videoRef = useRef();

  const playVideo = () => {
    videoRef.current.play();
  };
  const stopVideo = () => {
    if (videoRef.current.onplaying) {
      videoRef.current.pause();
      videoRef.current.currentTime = 0;
    }
  };

  return (
    <section
      className="flex flex-col lg:flex-row lg:h-[700px] transition-all ease-in-out duration-500 group/spotlight"
      onMouseEnter={playVideo}
      onMouseLeave={stopVideo}
    >
      <div className="flex lg:items-end py-4 px-6 lg:py-10 lg:px-0 border-b lg:border-b-0 lg:border-r border-black bg-limestone lg:group-hover/spotlight:bg-charcoal lg:group-hover/spotlight:text-limestone transition">
        <h3 className="font-dm-sans font-bold text-2.5xl lg:text-3xl uppercase whitespace-nowrap lg:-rotate-90 lg:mb-10 flex w-32">
          {project.short_name.text}
        </h3>
      </div>
      <div className="flex flex-col lg:flex-row w-full">
        <div
          className="flex justify-center items-center py-10 lg:py-0 px-6 xl:px-0 mx-auto w-full sm:w-2/3 lg:w-3/5
            lg:translate-x-1/4 lg:group-hover/spotlight:translate-x-0
            transition-multiple ease-in-out duration-500 relative"
        >
          <Image
            image={project.video_poster_image}
            alt={project.video_poster_image.alt}
            withFrame
            className="w-full lg:w-[600px] block group-hover/spotlight:hidden"
          />
          <video
            src={project.video_preview.url}
            ref={videoRef}
            poster={project.video_poster_image.url}
            preload="auto"
            muted
            loop
            className="w-full lg:w-[600px] border-black border-[6px] rounded-[18px] lg:border-8 hidden group-hover/spotlight:block"
          />
        </div>
        <div
          className="lg:w-2/5 lg:translate-x-3/4 lg:group-hover/spotlight:translate-x-0
        transition-multiple ease-in-out duration-500 lg:bg-grey-smoke lg:border-l"
        >
          {project.flag && (
            <Flag pin="left" className="-translate-x-full pl-8 !pr-4 top-4">
              {project.flag}
            </Flag>
          )}
          <div className="lg:opacity-0 lg:group-hover/spotlight:opacity-100 flex flex-wrap w-full h-full transition-opacity ease-in-out duration-500">
            <div className="grid grid-rows-projDetailMobile md:grid-cols-2 xl:grid-rows-projDetailHover w-full border-t lg:border-t-0">
              <Heading
                level="h6"
                className="col-span-1 flex items-center px-6 lg:px-8 border-r 2xl:whitespace-nowrap lg:text-base 2xl:text-xl h-full"
              >
                {project.project_type}
              </Heading>
              <div className="col-span-1 flex items-center px-6 lg:px-8 2xl:whitespace-nowrap">
                <Level
                  lowerLevel={project.project_level_lower_end}
                  upperLevel={project.project_level_upper_end}
                  topTight
                />
              </div>
              {project.tags && project.tags.length > 0 && (
                <div className="col-span-2 flex items-center flex-wrap gap-2 sm:gap-3 lg:gap-4 px-6 lg:px-8 py-3 border-t">
                  {project.tags.map((tag, i) => {
                    return (
                      <Tag
                        key={i}
                        name={tag.tag_name}
                        isRequired={tag.language_is_required}
                      />
                    );
                  })}
                </div>
              )}
              <div className="col-span-2 border-t p-6 lg:p-8">
                <Paragraph className="mb-6">
                  {project.intro_to_highlights}
                </Paragraph>
                <List items={project.project_highlights} itemKey="highlight" />
              </div>
              <div className="col-span-2 border-t border-black h-16 self-end group/cta cursor-pointer">
                <ArrowLink
                  cta="Build this"
                  to={`projects/${slug}`}
                  className="col-span-1 justify-end items-center w-full border-apricot px-8 h-full"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default ProjectSpotlight;

ProjectSpotlight.propTypes = {
  project: PropTypes.shape({}),
  tags: PropTypes.arrayOf(PropTypes.string),
};
