import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";

import Heading from "./Heading";
import Paragraph from "./Paragraph";

function Subsection({
  title,
  intro,
  noPadding = false,
  children,
  className = null,
}) {
  return (
    <section
      className={cx(!noPadding && "py-6 md:py-10", className && className)}
    >
      <div className="mb-8 md:mb-10 lg:mb-12">
        <Heading
          level="h6"
          withLineAfter
          className="pt-4 md:pt-8 pb-0 md:mr-10 whitespace-nowrap"
        >
          {title}
        </Heading>
      </div>

      {intro && (
        <Paragraph size="sm" className="md:w-4/5 lg:w-3/5 mb-12 md:mb-20">
          {intro}
        </Paragraph>
      )}

      <>{children}</>
    </section>
  );
}

export default Subsection;

Subsection.propTypes = {
  title: PropTypes.string,
  intro: PropTypes.string,
  noPadding: PropTypes.bool,
  children: PropTypes.node,
  className: PropTypes.string,
};
