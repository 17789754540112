import React, { useContext } from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import { useStaticQuery, graphql } from "gatsby";

import Paragraph from "./Paragraph";
import Subsection from "./Subsection";
import Modal from "./Modal";
import Label from "./Label";
import { ModalContext } from "../../context/modalContext";

const LevelShape = ({ fill, size = "base" }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill={fill ? "#111" : "none"}
      viewBox="0 0 21 21"
      className={cx(size === "small" ? "w-4" : "w-4 xl:w-5")}
    >
      <path
        stroke="#111"
        d="M19.95.5a19.795 19.795 0 0 1-1.513 7.103 19.864 19.864 0 0 1-4.325 6.446 19.963 19.963 0 0 1-6.474 4.308A20.028 20.028 0 0 1 .5 19.863V.5h19.45Z"
      />
    </svg>
  );
};

LevelShape.propTypes = {
  fill: PropTypes.bool,
  size: PropTypes.oneOf(["sm", "base", "lg", "xl"]),
};

function Level({
  lowerLevel,
  upperLevel,
  withLabel = false,
  showExplanation = true,
  topTight = false,
  size = "base",
  className = null,
}) {
  const { openModalKey, setOpenModalKey } = useContext(ModalContext);

  const allLevels = [1, 2, 3, 4, 5];

  const data = useStaticQuery(graphql`
    {
      prismicProjectLevels {
        data {
          level_details {
            number
            name
            description
          }
        }
      }
    }
  `);

  const { level_details } = data.prismicProjectLevels.data;

  const shouldFill = (level) => {
    if (level >= Number(lowerLevel) && level <= Number(upperLevel)) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <section className={cx("flex items-center", className && className)}>
      {withLabel && (
        <Label className="mr-3 order-1" filled bold>
          Level
        </Label>
      )}
      <div
        className={cx("flex gap-1 xl:gap-2", withLabel ? "order-3" : "order-2")}
      >
        {allLevels.map((level, i) => {
          return <LevelShape key={i} fill={shouldFill(level)} size={size} />;
        })}
      </div>
      {showExplanation && (
        <span
          className={cx(
            "relative cursor-pointer text-xs text-grey-medium group/explanation flex items-center h-6",
            withLabel ? "order-2 mr-4" : "order-3 ml-4",
          )}
          onClick={() => setOpenModalKey("PROJ_LEVEL_EXPLAINER")}
        >
          <span
            className={cx(
              "hidden md:block absolute right-0 underline whitespace-nowrap opacity-0 group-hover/explanation:opacity-100 transition-opacity",
              topTight ? "top-5" : "top-8",
            )}
          >
            What’s my level?
          </span>
          <span className="flex justify-center items-center border-1 rounded-full w-4 h-4">
            ?
          </span>
        </span>
      )}

      {showExplanation && openModalKey === "PROJ_LEVEL_EXPLAINER" && (
        <Modal>
          <Subsection title="What’s my level?">
            {level_details.map((level, i) => {
              return (
                <div
                  className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-[400px_1fr] items-start mb-10 md:mb-8"
                  key={i}
                >
                  <div className="flex mb-4">
                    <div className="flex gap-2 mr-10 md:mr-20">
                      {allLevels.map((levelNumber, index) => (
                        <LevelShape
                          fill={
                            Number(level.number) === levelNumber ? true : false
                          }
                          key={`level-${level.number}-${index}`}
                        />
                      ))}
                    </div>
                    <Label uppercase bold={true}>
                      {level.number} - {level.name}
                    </Label>
                  </div>
                  <Paragraph>{level.description}</Paragraph>
                </div>
              );
            })}
          </Subsection>
        </Modal>
      )}
    </section>
  );
}

export default Level;

Level.propTypes = {
  lowerLevel: PropTypes.oneOf(["1", "2", "3", "4", "5"]),
  upperLevel: PropTypes.oneOf(["1", "2", "3", "4", "5"]),
  showExplanation: PropTypes.bool,
  withLabel: PropTypes.bool,
  topTight: PropTypes.bool,
  size: PropTypes.oneOf(["sm", "base", "lg", "xl"]),
  className: PropTypes.string,
};
