import * as React from "react";
import { graphql } from "gatsby";

import CustomHead from "../components/common/Head";
import HomepageTemplate from "../templates/homepage";

export const query = graphql`
  query HomepageQuery {
    prismicHomepage {
      data {
        page_title
        page_description
        body {
          ... on PrismicHomepageDataBodyHero {
            slice_type
            primary {
              heading {
                text
              }
              heading_line_2 {
                text
              }
              subheading {
                text
              }
              subheading_line_2 {
                text
              }
            }
          }
          ... on PrismicHomepageDataBodyStatement {
            slice_type
            primary {
              statement {
                text
              }
            }
          }
          ... on PrismicHomepageDataBodyFeatures {
            slice_type
            primary {
              heading {
                text
              }
            }
            items {
              feature_heading {
                text
              }
              feature_description {
                text
              }
              feature_icon {
                gatsbyImageData(
                  placeholderImgixParams: { blur: 10 }
                  placeholder: BLURRED
                )
                alt
              }
            }
          }
          ... on PrismicHomepageDataBodyFeaturedChallenges {
            slice_type
            primary {
              heading {
                text
              }
              intro
            }
            items {
              featured_challenge {
                slug
                document {
                  ... on PrismicProject {
                    data {
                      short_name {
                        text
                      }
                      short_description {
                        text
                      }
                      thumbnail {
                        gatsbyImageData(
                          placeholderImgixParams: { blur: 10 }
                          placeholder: BLURRED
                        )
                        alt
                      }
                      video_poster_image {
                        gatsbyImageData(
                          placeholderImgixParams: { blur: 10 }
                          placeholder: BLURRED
                        )
                        url
                        alt
                      }
                      video_preview {
                        url
                      }
                      flag
                      intro_to_highlights
                      project_highlights {
                        highlight
                      }
                      project_type
                      project_level_lower_end
                      project_level_upper_end
                      tags {
                        tag_name
                        language_is_required
                      }
                    }
                  }
                }
              }
            }
          }
          ... on PrismicHomepageDataBodyJoinUs {
            slice_type
            primary {
              main_join_us_cta
              main_cta_detail
              sign_up_button_label
              shape_select
            }
            items {
              large_cta_part
            }
          }
        }
      }
    }
  }
`;

const HomepageWithData = ({ data, location }) => {
  return <HomepageTemplate data={data?.prismicHomepage} location={location} />;
};

export const Head = ({ data }) => {
  const pageMeta = {
    title: data.prismicHomepage.data.page_title,
    description: data.prismicHomepage.data.page_description,
  };

  return <CustomHead pageMeta={pageMeta} />;
};

export default HomepageWithData;
