import React from "react";

import Button from "./Button";
import Paragraph from "./Paragraph";

import {
  MC_AUDIENCE_ALL_CUSTOMERS,
  MC_TAG_NEWSLETTER_SIGNUP,
} from "../../constants/marketing";

// On sign up, Mailchimp redirects to /?params=subscribed
// We then use this to display a "Thank you" message on the page

function SubscribeForm() {
  return (
    <form
      action="https://codecaravan.us21.list-manage.com/subscribe/post"
      method="post"
    >
      <div className="flex flex-col sm:flex-row gap-4 md:gap-6 pb-4">
        <input type="hidden" name="u" value="6e48324a98f4850b4e96b1835" />
        <input type="hidden" name="id" value={MC_AUDIENCE_ALL_CUSTOMERS} />
        <input type="hidden" name="tags" value={MC_TAG_NEWSLETTER_SIGNUP} />
        <input
          type="text"
          placeholder="Your first name"
          name="MERGE1"
          id="MERGE1"
          autoCapitalize="off"
          autoCorrect="off"
          className="text-sm py-2 px-4 border-black border-2 placeholder:text-md placeholder:text-grey-medium placeholder:font-medium sm:mb-0 w-100 xl:w-48 max-w-xl"
        />
        <input
          type="email"
          placeholder="Your email"
          name="MERGE0"
          id="MERGE0"
          autoCapitalize="off"
          autoCorrect="off"
          className="text-sm py-2 px-4 border-black border-2 placeholder:text-md placeholder:text-grey-medium placeholder:font-medium w-100 xl:w-80 max-w-xl"
        />
      </div>
      <Paragraph size="xxs" colour="charcoal">
        By signing up you agree to receiving occasional emails from CodeCaravan
        about new projects and learning opportunities.
      </Paragraph>
      <Button size="sm" submit className="mt-6">
        Sign up
      </Button>
    </form>
  );
}

export default SubscribeForm;
